export default {
    apiBase: 'https://api.larosa.santfeliu.cat',
    clientID: '4_1kcy4pzz207488csw444g0wokw0skk4o4o0o00co8g0sk4c0g4',
    clientSecret: '4vddyd9u9deswk0g8sg4408w80w0scsscwkcg4k04o88k8sc40',
    lng: "es",
    type: 'la_rosa',
    crypto_currency: 'ROSA',
    pollingInterval: 15e3,
    recScaleFactor: Math.pow(10, 8),
    version: '1.0.0',
}